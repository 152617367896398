<template>
  <div class="container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>会员消费统计</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>消费排序：</span>
      <el-select v-model="formData.orderType">
        <el-option :value="1" label="订单数量"></el-option>
        <el-option :value="2" label="消费总金额"></el-option>
      </el-select>
      <span>开始时间：</span>
      <el-date-picker v-model="formData.beginTime" type="datetime" placeholder="选择日期时间" align="right" value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions">
      </el-date-picker>
      <span>结束时间：</span>
      <el-date-picker v-model="formData.endTime" type="datetime" placeholder="选择日期时间" align="right" value-format="yyyy-MM-dd HH:mm:ss"
        @change="endTimeChange" :picker-options="pickerOptions2">
      </el-date-picker>
      <el-button type="primary" class="btn" @click="getTableList()">查询</el-button>
    </div>

    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="userName" label="会员名">
      </el-table-column>
      <el-table-column prop="phone" label="phone">
      </el-table-column>
      <el-table-column prop="orderCount" label="订单数量">
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="formData.pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total">
    </el-pagination>
  </div>
</template>

<script>
  import { memberBuys } from '../../api/Statistics.js'
  import {
    sendFile
  } from "../../api/sendFile";
  export default {
    name: "memberBuys",
    data() {
      return {
        options: [],
        formData: {
          total: 0,
          pageSize: 10,
          currentPage: 1,
          orderType: 1,
          beginTime: '',
          endTime: '',
        },
        editForm: {
          cashId: '',
          remark: '',
        },
        tableData: [],
        multipleSelection: [],
        dialogVisible: false,
        dialogVisible2: false,
        pickerOptions: {
          // 是否禁用当前日期之前的日期
          disabledDate: (time) => {
            let beginTime = time.getTime();
            let endTime = new Date(this.formData.endTime).getTime();
            // console.log(beginTime, '状态',status, endTime)
            // 小于开始时间的日期返回 true,禁止选择
            return beginTime > endTime
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        pickerOptions2: {
          // 是否禁用当前日期之前的日期
          disabledDate: (time) => {
            let endTime = time.getTime();
            let beginTime = new Date(this.formData.beginTime).getTime();
            // console.log(beginTime, '状态',status, endTime)
            // 小于开始时间的日期返回 true,禁止选择
            return beginTime > endTime
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        }
      }
    },
    created() {
      this.getTableList(); // 获取表格数据
    },
    methods: {
      async getTableList() {
        const {
          data
        } = await memberBuys(this.formData);
        data.list.forEach((item, index) => {
          item.dept = index + 1
        })
        this.tableData = data.list;
        this.formData.total = data.pagination.total
        this.formData.pageSize = data.pagination.pageSize;
        this.formData.currentPage = data.pagination.current;
      },
      showApply(row) {
        this.editForm.cashId = row.cashId
        this.editForm.remark = ""
        this.dialogVisible = true
      },
      // 隐藏弹窗
      hideVisible() {
        this.dialogVisible = false
        this.dialogVisible2 = false
      },
      handleSizeChange(num) {
        console.log(num)
        this.formData.pageSize = num;
        this.getTableList()
      },
      handleCurrentChange(num) {
        console.log('currentPage', num)
        this.formData.currentPage = num;
        this.getTableList()
      },
      endTimeChange(endDate) {
        let beginTime = new Date(this.formData.beginTime).getTime();
        let endTime = new Date(this.formData.endTime).getTime();
        console.log(beginTime, endTime)
        if (beginTime && endTime < beginTime) {
          this.formData.endTime = ''
          return this.$message.error('结束日期不能小于开始日期!')
        }
      },
    }
  };
</script>

<style lang="less" scoped>
  .container {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }

    .add {
      margin-top: 20px;
    }

    .el-table {
      margin-top: 50px;
    }

    .search_box {
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .btn {
        margin: 0 20px;
      }

      span {
        display: inline-block;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .el-pagination {
      margin-top: 50px;
      float: right;
    }

    /deep/.el-dialog__header {
      border-bottom: 1px solid #F1F1F1;
    }

    /deep/.el-dialog__footer {
      border-top: 1px solid #F1F1F1;
    }

    .btn_box {
      margin-top: 24px;
    }

    .modify_btn {
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/.popconfirm {
      margin-right: 10px;
    }

  }
</style>


